import React, { useState, useEffect, useContext } from "react";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import ZodiacDD from "../../../components/ZodiacDD";
import banner from "../../../staticAssets/Horoscope Bg.png";
import logo from "../../../staticAssets/Gemini.png";
import axios from "axios";
import { navigate } from "gatsby";
import { AuthContext } from "../../../context/Auth";
import { Helmet } from "react-helmet-async";
import { AnalyticsContext } from "../../../context/Analytics";

function Index({location}) {
  const [blogs, setblogs] = useState([]);
const { gtmPageViewEvent } = useContext(AnalyticsContext) || {
    gtmPageViewEvent: () => {},
  };
  const { user } = useContext(AuthContext) || {
    user: {},
  };
  useEffect(() => {
    const fn = async () => {
      let res = await axios.get(
        "https://www.bodhi.app/blog/wp-json/wp/v2/posts"
      );

      if (res.status == 200) {
        setblogs([...res.data]);
      }
    };
    gtmPageViewEvent(window.location.pathname, window.location.host);
fn();
  }, []);
  return (
    <div className="h-screen flex flex-col block hide-scroll-bar scrollbar-hide worksans w-screen overflow-x-clip">
      <Header />
      <Helmet>
        <link
          rel="canonical"
          href={"https://www.bodhi.app"+location?.pathname}
        />
      </Helmet>
      <div className="flex-1 overflow-y-auto flex flex-col">
        <main className="grow hide-scroll-bar scrollbar-hide">
          <div className=" w-full relative bg-[#FFEEEF] overflow-hidden">
            <img className="h-32 md:h-auto w-full object-fill" src={banner} />
            <div className="absolute top-0 w-full h-full flex justify-center items-center">
              <h1 className="text-center text-2xl md:text-4xl font-semibold text-white">
                Gemini Man
              </h1>
            </div>
          </div>
          <div className="container mx-auto flex flex-col">
            <div className="flex flex-wrap my-2 ml-2">
              <a
                href="https://www.bodhi.app/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Bodhi</h4>
              </a>
              
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Zodiac Signs</h4>
              </a>
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/gemini"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Gemini</h4>
              </a>
              <h4 className="mx-2 text-sm">/</h4>
              <a
                className="text-gray-500 mx-2"
              >
                 Gemini man
              </a>
              
              <h4 className="mx-2 text-sm">/</h4>
              <h4 className="text-sm"></h4>
            </div>
            <div className="flex mt-10 items-center">
              <div>
                <img className="h-24 md:h-48" src={logo} />
              </div>
              <div className="flex-1 flex flex-col md:flex-row">
                <div className=" flex-1 flex flex-col">

                <h1 className="font-semibold text-xl md:text-4xl ml-4">Gemini Man </h1>
                <h2 className="text-sm md:text-base ml-4">May 21 - Jun 21</h2>
                </div>
                <div className="">
                <ZodiacDD name={"Change Sunsign"} />
              </div>
              </div>
              
            </div>
            <div className="grid grid-cols-3 md:grid-cols-4 gap-2 md:gap-8 mt-10 text-[0.8rem] md:text-base mx-1">
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2 py-1" onClick={() => {
                    navigate("/zodiac-signs/gemini/gemini-woman");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Gemini Woman</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>

              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/gemini/gemini-health");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Gemini Health</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/gemini/gemini-career");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Gemini Career</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/gemini/gemini-love");
                  }}>
                  <h5 className="font-semibold text-black">Gemini Love</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/gemini/gemini-nature");
                  }}>
                  <h5 className="font-semibold text-black">Gemini Nature</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/gemini/gemini-man");
                  }}>
                  <h5 className="font-semibold text-black">Gemini Man</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/gemini/gemini-relationship");
                  }}>
                  <h5 className="font-semibold text-black">Gemini Relationship</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/gemini/gemini-traits");
                  }}>
                  <h5 className="font-semibold text-black">Gemini Traits</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/gemini/gemini-facts");
                  }}>
                  <h5 className="font-semibold text-black">Gemini Facts</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
            </div>
            <span className="my-4 mx-1 md:mx-0">
            <p className="playfair text-black text-xl md:text-3xl">
                Gemini Man Characteristics
              </p>
              <br/>
<p className="font-bold text-orange-500 inline-block mr-2">Traits:</p>Communication, adventurous, easily bored, intelligent and intuitive, versatile, curious, unpredictable, impatient, overbearing
 <br/><br/>
 <p className="font-bold text-orange-500 inline-block mr-2">Compatibility Signs for Aries Men:</p><br/><br/>
Aries, Libra, Scorpio, Sagittarius, and Aquarius
 <br/><br/>
Gemini is the sign of the twins. People born under the constellation of Gemini are curious and social, which makes them excellent at communication and people skills. They are very interested in and open to learning about other people and their experiences, which helps them to understand the perspectives of others. Gemini people are generally very approachable, so it's not uncommon for people to come across as hyper-friendly to them – but don't be fooled; these are just their friendly demeanor.
 <br/><br/>
Gemini is the third sign of the zodiac, which means it falls under the mutable air element. This means that Geminis are incredibly extroverted, talkative, and friendly. They are often described as "two-faced" and "twisted" because they can be quite charming, outgoing, distant, and detached. This alternation of behavior stems from their dominant sign of Gemini, which is known for its mutable nature.
 <br/><br/>
You are a true introvert, but you are also a romantic at heart. You think deeply about life, the universe, and everything. You're not very good at showing your emotions, but when you do, it's special. You're a scholar who is always looking for new learnings and knowledge.<br/><br/>
You are a very independent person who likes to do things your way. You are a realist who is not one to dwell on the negative and are always looking on the bright side. You are a scholar who is always looking for new learnings and knowledge. You are a realist who is not one to dwell on the negative and are always looking on the bright side.
 <br/><br/>
 They are social animals and need interaction with other people to be happy. You can't have a conversation with a Gemini man for more than five minutes without him wanting to tell you about the latest football game or the best restaurant to eat at.
 <br/><br/>
It isn't easy to pin down your essence. You are the union of light and dark, good and bad, beautiful and ugly, captivating and mysterious. You are the balance of both feelings, the yin and yang, the bright and the dark. This makes you difficult to pin down, which is often a good thing.
 <br/><br/>
 
 
 <p className="playfair text-black text-xl md:text-3xl">Let's take a look at Gemini men's traits.</p>
 <br/>
 <p className="playfair text-black text-xl md:text-3xl">Communication</p><br/>
You are a fleet-footed messenger, and perfectly delightful conversationalist as Mercury governs you. You are a very expressive person, so it is hard to be around you for long periods without smiling, laughing, crying, or arguing. You are always in motion, either with your words or your body, necessary to keep up with your brain. Your body is a machine, moving and processing information quickly. This works great for you because it allows you to keep up with your busy mind, but it can be tiring for those around you.
 <br/><br/>
 <p className="playfair text-black text-xl md:text-3xl">Adventurous</p><br/>
You are a passionate, enthusiastic, and adventurous person. You love life and are always looking forward to the next thing. You are a risk-taker and always willing to try something new. You don't like to waste time, and you are constantly searching for that next best thing.<br/><br/>
You have a great appreciation for nature and everything it has to offer. You enjoy the outdoors and feel a deep connection to the environment. You are always happy to go on an adventure.<br/><br/>
You don't like to waste any time and always look for ways to utilize that precious time.<br/><br/>
You are always inclined towards things that are novel and exciting.
 <br/><br/>
 <p className="playfair text-black text-xl md:text-3xl">Easily bored</p><br/>
You are curious, clever, witty, bright, and get bored very quickly. You have an unquenched thirst for experiencing new things and having changes in your life.<br/><br/>
You are outgoing, friendly and enjoy meeting new people. You are not afraid to show your interest and can be pretty assertive. You can be too outspoken at times, especially when you do not know someone well.
 <br/><br/>
You are a free thinker who is a bit of a dreamer. You are a self-starter who is not afraid to try new things, and you don't let others tell you what to do! You are a person who is not afraid to speak your mind, and you take risks to pursue your dreams.
 <br/><br/>
You are a restless person who is always on the move. You are not afraid to try new things or take risks, and you do not let anything stand in your way. You are a person who is not afraid to speak your mind, and you take risks to pursue your dreams. You are a free spirit who lives life on the edge.
 
 <br/><br/>
 <p className="playfair text-black text-xl md:text-3xl">Intelligent and intuitive</p><br/>
You are intelligent and intuitive. You possess surface knowledge of many subjects. Gemini love to share knowledge with your friends, associates, and strangers around you. As you are inclined to change, sometimes you need to change your behavior, which is not always easy.
 <br/><br/>
 
You have a strong sense of right and wrong, good and evil, and are willing to speak up when you see something against these morals. You have an extensive knowledge of our society's norms and traditions, which allows you to speak up when something goes against these morals. You tend to share what you think others want to hear rather than what will help them. Your intuitions are powerful, and you act according to them.
 <br/><br/>
 
 <p className="playfair text-black text-xl md:text-3xl">Versatile</p><br/>
You are an expert at multitasking. You are a multi-tasker. You can handle a lot of different things at once. You are good at doing several things at the same time.
 <br/><br/>
You are a good skimmed reader and acquire tidbits of knowledge of everything but rarely go skin deep. You have a multitude of interests and hence tend to get sidetracked easily. You are interested in everything and can learn almost anything. You are not easily influenced by what others say or do. You can know anything.
 <br/><br/>
 
<p className="playfair text-black text-xl md:text-3xl">Overbearing</p><br/>
This section is to give you a variety of ideas as to how to have your social life improve. The questions are meant to create various theories about what you can do to help. The first is that you cannot see what you are doing. You get caught by your thoughts as they appear to you. You are not able to know what you are doing or thinking clearly. You are very demanding at times.
 <br/><br/>
<p className="playfair text-black text-xl md:text-3xl">Competitive and challenging</p><br/>
Gemini men are competitive and challenging. They have a natural tendency to be in a competitive relationship. Gemini men would like to be in a relationship that makes them feel equal to their partners. They enjoy being in a competitive relationship.
 <br/><br/>
You are attracted to a woman who can keep up with your bold, sometimes brash personality. You want a woman who is as strong-willed and independent as you are and who can fuel your intellect and lust. Your type is often a woman who has her career and is not afraid to be in power. You want a woman who can challenge you and keep you on your toes instead of being a pushover like so many other women.
 <br/><br/>
<p className="playfair text-black text-xl md:text-3xl">Curious</p><br/>
You are constantly in the search for knowledge. Your thirst for knowledge is insatiable, and it is not limited to books and classes. You are continually doing things to expand your knowledge, such as attending lectures, conversations with teachers and other students, and reading anything and everything you can get your hands on. You are constantly seeking new experiences and ways to learn, and you are never happy with your current level of knowledge.
 
 <br/><br/>
 
AS a curious person, you like new things, and you want to learn new things. As a result, you will try to learn as much as possible. You can be a good learner. It is essential to know that you get interested immediately in what you learn.
 <br/><br/>
Your curiosity to know everything will involve too many things at a time. You may even leave the undertaken task and jump to a new one to learn about it. This kind of learning can take you to a new level of knowledge and understanding.
 
 <br/><br/>
<p className="playfair text-black text-xl md:text-3xl">Unpredictable</p><br/>
You are your person and no one else. You are unpredictable and have two different minds, one being quiet and rational, the other being loud and emotional. You are constantly changing your mind, and because of this, others find you despicable. But at the same time, people find you attractive because of your dubious nature and unpredictability.
 <br/><br/>
You have learned to fit in any situation, be it a fancy party or a rough camp in the woods. You have no particular tastes for a lifetime, and even in the face of danger, you will never show fear. You have learned to survive by fitting in and have become a master of concealment and disguise. You have been in many situations where you knew you had to keep quiet, yet you never showed fear.
 <br/><br/>
<p className="playfair text-black text-xl md:text-3xl">Impatient</p><br/>
Your emotional nature will come up with a price. You are curious about the world, and you are constantly questioning how the world is. You want to know the world, and you want others to see the way you see the world. You want to learn how others see the world, and you want to know the way others feel about the world. You want others to see what you know.
 
 <br/><br/>
 <p className="playfair text-black text-xl md:text-3xl">How is Gemini man in love and relationships?</p>
 <br/><br/>
Gemini man is very emotional, sensitive, and creative. He is very intuitive and can be very funny. He can be moody and moody. He is very enthusiastic and enthusiastic.<br/><br/>
You are an idealist; you want to change the world. You are a rebel and a rebel leader. You are a visionary. You are a leader.
 
 <br/><br/>
Gemini men are the ultimate romantics. They are passionate and fun-loving but also very aware of their needs for independence and freedom. As such, they are difficult to bond with for a long time. You may find yourself growing distant from Gemini men as they become more independent and need to spend more time on their own.
 <br/><br/>
<p className="">Gemini men are natural entertainers:</p>they love being in a group but also need time. They often find it difficult to last in a relationship for a long time but are also known to be very fun-loving and affectionate. They need to feel needed and will often put their partners first. They tend to be very practical and have a lot of friends.
 <br/><br/>
You need to feel that your partner loves you as much as you love them, and you will be fine. You are good at sustaining a long-distance relationship, but you feel like you need to be involved in your partner's life.
 <br/><br/>
 You are gentle, exciting, unpredictable, detached, and can certainly make your partner laugh.
 <br/><br/>
 <p className="playfair text-black text-xl md:text-3xl">How To Attract Gemini Men?</p><br/>
If you want to know how to connect and attract Gemini individuals, don't miss out on the below tips! Ready to discover how to draw a Gemini?<br/><br/>
<p className="font-bold text-orange-500 inline-block mr-2">Be curious:</p>Gemini is fascinated by the world around them and loves making connections between disciplines. Gemini individuals are always interested in their world and love to learn about new things. They are great at connecting the dots and coming up with ideas and solutions to problems.
 <br/><br/>
 <p className="font-bold text-orange-500 inline-block mr-2">Make plans:</p>There's never a dull moment with a Gemini. They have a real zest for life and a natural sense of adventure. So be enthusiastic and vigorous to attract them towards you.
 <br/><br/>
 <p className="font-bold text-orange-500 inline-block mr-2">Impress them intellectually:</p>Courageous moves taken by you will attract Gemini towards you. Nothing impresses a Gemini more than an individual who challenges them intellectually. Show off your smartness and deliver them with studious motivation; discuss society-related issues, share your technical learning, and give your thoughts. In short, be open-minded.
 <br/><br/>
            </span>
          </div>
          <div className="container mx-auto">
            <div className="flex justify-between mb-4 mx-2">
              <h1 className="text-xl md:text-3xl text-black playfair">
                Recent Blogs
              </h1>
              <a
                className="text-sm md:text-md text-orange-500 text-center font-semibold"
                href="https://www.bodhi.app/blog/"
              >
                See More
              </a>
            </div>
            <div className="relative">
                <div className="absolute top-0 right-0 w-0 md:w-36 fixed h-full bg-gradient-to-r from-transparent to-white"></div>
              <div className="flex overflow-x-scroll pb-4 mt-2 hide-scroll-bar scrollbar-hide">
                <div className="flex flex-nowrap ml-2">
                  {blogs.slice(0, 10).map((item, i) => {
                    return (
                      <a href={item.link} key={i} target="_blank">
                        <div className="flex flex-col h-80 w-52 justify-start items-start bg-gray-100 mr-10 mb-10 rounded-lg overflow-hidden hover:shadow-lg">
                          <img
                            className="object-cover w-52 h-52 w-auto bg-gray-100"
                            src={
                              item?.yoast_head_json?.og_image
                                ? item?.yoast_head_json?.og_image[0]?.url
                                : ""
                            }
                          />
                          <span className="mt-2 block mx-2 text-center">
                            {item?.yoast_head_json?.title ?? ""}
                          </span>
                        </div>
                      </a>
                    );
                  })}
                  {/* <div className="flex h-80 w-52 justify-center items-center "></div> */}
                </div>
              </div>
            </div>
          <button
            className="justify-center text-white fixed right-2 bottom-2 rounded-t-2xl but px-4 py-2 flex gap-1 shadow-xl"
            onClick={() => {
              navigate("/consultAstro");
            }}
          >
            {user.name !== undefined?`Consult Top Astrologers Now!`:`Login & get ${
              user?.currencyType == "usd" ? "$ 4" : "₹ 91"
            } in your account!`}
          </button>
          </div>
        </main>
        <Footer />
      </div>
    </div>
  );
}

export default Index;
